import { LovList, LovAddView } from '../models/lov.model';
import { CountryModel, CountryAddModel, LOVCountryModel } from '../models/country.model';
import { StateModel } from '../models/state.model';
import { CityModel } from '../models/city.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LanguageModel, LanguageAddModel, LOVLanguageModel } from '../models/language.model';
import { ReleaseNumberAddViewModel } from '../models/release-number-model';
import { SearchFilterAddViewModel, SearchFilterListViewModel } from '../models/search-filter.model';
import { 
  AgeRangeList,
  EducationalStage,
  ResetPasswordModel,
  BulkDataImportExcelHeader,
  ChangePasswordViewModel,
  ActiveDeactiveUserModel,
  DatabaseBackupModel,
  GetSchoolDateTimeSettingsModel, 
  AddUpdateSchoolDateTimeSettingsModel,
  GetAuditHistoryModel } from '../models/common.model';
import { DefaultValuesService } from '../common/default-values.service';
import { CryptoService } from './Crypto.service';
import { BehaviorSubject, fromEvent, merge, Observable, Observer } from 'rxjs';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfilesTypes } from '../enums/profiles.enum';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl: string = environment.apiURL;
  private searchResult;
  private moduleName: string;

  private changeLanguage = new BehaviorSubject(false);
  changedLanguage = this.changeLanguage.asObservable();

  private userLoginError = new BehaviorSubject(false);
  userLoginErrorTriggered = this.userLoginError.asObservable();

  private triggerUserActivity = new BehaviorSubject(false);
  triggeredUserActivity = this.triggerUserActivity.asObservable();
  httpOptions: { headers: any; };
  httpOptionsForBackup: { headers: HttpHeaders; };
  profiles = ProfilesTypes;

  constructor(
    private http: HttpClient,
    private defaultValuesService: DefaultValuesService,
    private cryptoService: CryptoService,
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private defaultValueService: DefaultValuesService,
    public translate: TranslateService,
    private dialog: MatDialog, 
  ) { this.httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    })
  }
  this.httpOptionsForBackup = {
    headers: new HttpHeaders({
      //"Content-type": "application/json",
       observe: 'events',
       responseType: 'blob'
    }),

    
  }
}

// GetAllCountry(obj: CountryModel) {
//   obj = this.defaultValuesService.getAllMandatoryVariable(obj);
//   let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllCountries";
//   return this.http.post<CountryModel>(apiurl, obj,this.httpOptions)
// }

  GetAllCountry() {
    // obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    // let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllCountries";
    // return this.http.post<CountryModel>(apiurl, obj,this.httpOptions);
    let apiurl = "allCountries.json";
    return this.http.get('./assets/'+apiurl);
  }

  LOVGetAllCountry() {
    // obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    // let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllCountries";
    // return this.http.post<LOVCountryModel>(apiurl, obj, this.httpOptions);
    let apiurl = "allCountries.json";
    return this.http.get('./assets/'+apiurl);
  }

  AddCountry(obj: CountryAddModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.country.createdBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/addCountry";
    return this.http.post<CountryAddModel>(apiurl, obj,this.httpOptions)
  }

  UpdateCountry(obj: CountryAddModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.country.updatedBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/updateCountry";
    return this.http.put<CountryAddModel>(apiurl, obj,this.httpOptions)
  }

  DeleteCountry(obj: CountryAddModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/deleteCountry";
    return this.http.post<CountryAddModel>(apiurl, obj,this.httpOptions)
  }

  GetAllState(obj: StateModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllStatesByCountry";
    return this.http.post<StateModel>(apiurl, obj,this.httpOptions)
  }
  GetAllCity(obj: CityModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllCitiesByState";
    return this.http.post<CityModel>(apiurl, obj,this.httpOptions)
  }
  GetAllLanguage(obj: LanguageModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllLanguage";
    return this.http.post<LanguageModel>(apiurl, obj,this.httpOptions)
  }

  LOVGetAllLanguage(obj: LOVLanguageModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllLanguage";
    return this.http.post<LOVLanguageModel>(apiurl, obj, this.httpOptions)
  }

  AddLanguage(obj: LanguageAddModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.language.createdBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/addLanguage";
    return this.http.post<LanguageAddModel>(apiurl, obj,this.httpOptions)
  }

  UpdateLanguage(obj: LanguageAddModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.language.updatedBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/updateLanguage";
    return this.http.post<LanguageAddModel>(apiurl, obj,this.httpOptions)
  }

  DeleteLanguage(obj: LanguageAddModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/deleteLanguage";
    return this.http.post<LanguageAddModel>(apiurl, obj,this.httpOptions)
  }
  getAllDropdownValues(obj: LovList, setSchoolIdNull?) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = setSchoolIdNull ? null : obj.schoolId;
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllDropdownValues";
    return this.http.post<LovList>(apiurl, obj,this.httpOptions);
  }
  getAllDropdownValueList(obj: LovList, setSchoolIdNull?) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = setSchoolIdNull ? null : obj.schoolId;
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllDropdownValuesforAddScreen";
    return this.http.post<LovList>(apiurl, obj,this.httpOptions);
  }
  addDropdownValue(obj: LovAddView) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.dropdownValue.schoolId = this.defaultValuesService.getSchoolID();
    obj.dropdownValue.tenantId = this.defaultValuesService.getTenantID();
    obj.dropdownValue.createdBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/addDropdownValue";
    return this.http.post<LovAddView>(apiurl, obj,this.httpOptions);
  }
  updateDropdownValue(obj: LovAddView) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.dropdownValue.schoolId = this.defaultValuesService.getSchoolID();
    obj.dropdownValue.tenantId = this.defaultValuesService.getTenantID();
    obj.dropdownValue.updatedBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/updateDropdownValue";
    return this.http.put<LovAddView>(apiurl, obj,this.httpOptions);
  }
  deleteDropdownValue(obj: LovAddView) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.dropdownValue.updatedBy= this.defaultValuesService.getUserGuidId();    
    obj.updatedBy = this.defaultValuesService.getUserGuidId();
    obj.dropdownValue.updatedBy = this.defaultValuesService.getUserGuidId();
    obj.dropdownValue.tenantId = this.defaultValuesService.getTenantID();
    obj.dropdownValue.schoolId = this.defaultValuesService.getSchoolID();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/deleteDropdownValue";
    return this.http.post<LovAddView>(apiurl, obj,this.httpOptions);
  }

  getReleaseNumber(obj: ReleaseNumberAddViewModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getReleaseNumber";
    return this.http.post<ReleaseNumberAddViewModel>(apiurl, obj,this.httpOptions);
  }

  addSearchFilter(obj: SearchFilterAddViewModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.searchFilter.createdBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/addSearchFilter";
    return this.http.post<SearchFilterAddViewModel>(apiurl, obj,this.httpOptions);
  }

  updateSearchFilter(obj: SearchFilterAddViewModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.searchFilter.updatedBy= this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Common/updateSearchFilter";
    return this.http.put<SearchFilterAddViewModel>(apiurl, obj,this.httpOptions);
  }

  deleteSearchFilter(obj: SearchFilterAddViewModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/deleteSearchFilter";
    return this.http.post<SearchFilterAddViewModel>(apiurl, obj,this.httpOptions);
  }

  getAllSearchFilter(obj: SearchFilterListViewModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllSearchFilter";
    return this.http.post<SearchFilterListViewModel>(apiurl, obj,this.httpOptions);
  }

  getAllGradeAgeRange(obj: AgeRangeList) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllGradeAgeRange";
    return this.http.post<AgeRangeList>(apiurl, obj,this.httpOptions)
  }

  getAllGradeEducationalStage(obj: EducationalStage) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllGradeEducationalStage";
    return this.http.post<EducationalStage>(apiurl, obj,this.httpOptions)
  }

  resetPassword(obj: ResetPasswordModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.userMaster.tenantId = this.defaultValuesService.getTenantID();
    obj.userMaster.schoolId = this.defaultValuesService.getSchoolID();
    obj.userMaster.updatedBy = this.defaultValuesService.getUserGuidId();
    obj.userMaster.passwordHash = this.cryptoService.encrypt(obj.userMaster.passwordHash);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/resetPassword";
    return this.http.post<ResetPasswordModel>(apiurl, obj,this.httpOptions)
  }

  activeDeactiveUser(obj: ActiveDeactiveUserModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/activeDeactiveUser";
    return this.http.post<ActiveDeactiveUserModel>(apiurl, obj, this.httpOptions);
  }

  getAllFieldList(obj: BulkDataImportExcelHeader) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/getAllFieldList";
    return this.http.post<BulkDataImportExcelHeader>(apiurl, obj,this.httpOptions)
  }

  changePassword(obj: ChangePasswordViewModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
  
    obj.userId=this.defaultValueService.getUserId();
    obj.emailAddress= this.defaultValuesService.getEmailId();
    obj.newPasswordHash = this.cryptoService.encrypt(obj.newPasswordHash);
    obj.currentPasswordHash = this.cryptoService.encrypt(obj.currentPasswordHash);
    let apiurl = this.apiUrl + obj._tenantName + "/Common/changePassword";
    return this.http.post<ChangePasswordViewModel>(apiurl, obj,this.httpOptions)
  }

  createDatabaseBackup(obj: DatabaseBackupModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/DBBackup/databaseBackup";
    return this.http.post(apiurl, obj, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    })
  }

  public download(obj: DatabaseBackupModel) {
    const params = new HttpParams()
    .set('sort', "description")
    .set('page',"2");
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/DBBackup/download1";
    return this.http.get(apiurl, { 'params': params, 'headers': headers })
    /*return this.http.get(`${this.url}/download?fileName=${fileName}`, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    });*/
  }

  getIpAddress() {
    return this.http.get('https://api64.ipify.org/?format=json');
  }

  setSearchResult(result) {
    this.searchResult = result;
  }
  getSearchResult() {
    return this.searchResult;
  }

  setModuleName(moduleName) {
    this.moduleName = moduleName;
  }
  getModuleName() {
    return this.moduleName;
  }

  triggerLanguageChange(data) {
    this.changeLanguage.next(data);
  }

  setUserActivity(data) {
    this.triggerUserActivity.next(data);
  }

  setUserLoginError(data) {
    this.userLoginError.next(data);
  }

  checkAndRoute() {
    if (this.loginService.isAuthenticated()) {
      if (this.defaultValuesService.getUserMembershipType() === this.profiles.Teacher || this.defaultValuesService.getUserMembershipType() === this.profiles.HomeroomTeacher) {
        this.router.navigate(['/school', 'teacher', 'dashboards']);
      } else if (this.defaultValuesService.getUserMembershipType() === this.profiles.Student) {
        this.router.navigate(['/school', 'student', 'dashboards']);
      } else if (this.defaultValuesService.getUserMembershipType() === this.profiles.Parent) {
        this.router.navigate(['/school', 'parent', 'dashboards']);
      } else {
        this.router.navigate(['/school', 'dashboards']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  checkAndRouteTo404() {
    if (this.defaultValueService.getIsForImpersonate()) {
      this.router.navigate(['/error', 'impersonation-error']);
    } else {
      if (this.loginService.isAuthenticated()) {
        this.router.navigate(['/school', 'error', '404']);
      } else {
        this.router.navigate(['/error', '404']);
      }
    }
  }

  logoutUser() {
    this.router.navigateByUrl('/logout');
  }

  checkTokenValidOrNot(message: string): boolean {
    if(message && (message).toLowerCase() === 'token not valid') {
      // this.snackbar.open('Another login with the same credentials was detected. Therefore, you have been logged out of the system.', '', {
      //   duration: 10000
      // });
      if (this.defaultValueService.getSourceName()) {
        window.close();
        return;
      }
      this.setUserLoginError(true);
      this.clearStorage();
      this.router.navigateByUrl('/');
      return true;
    }
  }

  clearStorage(){
      let schoolId =this.defaultValueService.getSchoolID();
      const tenantData = this.defaultValuesService.getTenantData();

      sessionStorage.clear();
      // localStorage.removeItem('clientId');
      // localStorage.removeItem('authority');
      // localStorage.removeItem('redirectUri');

      localStorage.removeItem(this.defaultValueService.permissionListKeyName);
      if(schoolId){
      this.defaultValueService.setSchoolID();
      }
      this.setUserActivity(true);
      this.defaultValueService.setDefaultTenant();
      this.defaultValuesService.setTenantData(tenantData);
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
    }

    isOnline() {
      return merge<boolean>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
          sub.next(navigator.onLine);
          sub.complete();
        }));
        // return new Observable((sub: Observer<boolean>) => {
        //   sub.next(navigator.onLine);
        //   sub.complete();
        // });
    }

    checkAndModifyPermissionData(dataSet) {
      if(this.defaultValuesService.getUserMembershipType() === this.profiles.Student ||  this.defaultValuesService.getUserMembershipType() === this.profiles.Parent) {
      dataSet.map((item)=>{        
        if(item.permissionGroup.permissionGroupName === 'Grades' && (this.defaultValuesService.getUserMembershipType() === this.profiles.Student || this.defaultValuesService.getUserMembershipType() === this.profiles.Parent)) {        
          item.permissionGroup.permissionCategory.map((subItem)=>{
            if(subItem.permissionCategoryName === 'Report Cards') {
              subItem.path = '/school/grades/student-report-card'
            } else if(subItem.permissionCategoryName === 'Transcripts') {
              subItem.path = '/school/grades/student-transcript';
              subItem.title = 'Transcript';
            }
          })
        } else if(item.permissionGroup.permissionGroupName === 'Scheduling' && this.defaultValuesService.getUserMembershipType() === this.profiles.Parent) {
          item.permissionGroup.permissionCategory.map((subItem)=>{
            
            if(subItem.permissionCategoryName === 'My Schedule') {            
              subItem.title = 'Course Schedule';
            } else if(subItem.permissionCategoryName === 'My Requests') {
              subItem.title = 'Course Requests';
            }
          })
        } else if(item.permissionGroup.permissionGroupName === 'Dashboard' && this.defaultValuesService.getUserMembershipType() === this.profiles.Parent) {
            item.permissionGroup.path = '/school/parent/dashboards';
        } 
         else if(item.permissionGroup.permissionGroupName === 'My Info' && this.defaultValuesService.getUserMembershipType() === this.profiles.Parent) {
          item.permissionGroup.title = 'Student Info';
        } else if(item.permissionGroup.permissionGroupName === 'Behavior' && (this.defaultValuesService.getUserMembershipType() === this.profiles.Student || this.defaultValuesService.getUserMembershipType() === this.profiles.Parent)){
          item.permissionGroup.path = '/school/student-behavior';
          
        }  
        // else if(item.permissionGroup.permissionGroupName === 'Billing and Fees' && (this.defaultValuesService.getUserMembershipType() === this.profiles.Parent ||  this.defaultValuesService.getUserMembershipType() === this.profiles.Student)) {
        //  item.permissionGroup.title = 'Fees and Charges';
        // }
        // else if (item.permissionGroup.permissionGroupName === 'Communication' && (this.defaultValuesService.getUserMembershipType() === this.profiles.Student || this.defaultValuesService.getUserMembershipType() === this.profiles.Parent)) {
        //   if (this.defaultValueService.getTenantData().zohoPlanId === 'core01') {
        //     item.permissionGroup.rolePermission[0].canView = false;
        //   }
        // }
      })      
      return dataSet;
    } else {
      return dataSet;
    }
    }

    getProfileCompletionDetails(obj){
      obj = this.defaultValuesService.getAllMandatoryVariable(obj)
      let apiurl = this.apiUrl + obj._tenantName + "/common/getProfileCompletionDetails";
      return this.http.post(apiurl, obj,this.httpOptions)
    }

    getSchoolDateAndTimezone(obj: GetSchoolDateTimeSettingsModel) {
      obj = this.defaultValuesService.getAllMandatoryVariable(obj);
      obj.schoolDateTimeSettings.tenantId = this.defaultValuesService.getTenantID();
      obj.schoolDateTimeSettings.schoolId = this.defaultValuesService.getSchoolID();
      let apiurl = this.apiUrl + obj._tenantName + "/Common/getSchoolDateTimeSettings";
      return this.http.post<GetSchoolDateTimeSettingsModel>(apiurl, obj,this.httpOptions)
    }

    addSchoolDateTimeSettings(obj: AddUpdateSchoolDateTimeSettingsModel) {
      obj = this.defaultValuesService.getAllMandatoryVariable(obj);
      obj.schoolDateTimeSettings.tenantId = this.defaultValuesService.getTenantID();
      obj.schoolDateTimeSettings.schoolId = this.defaultValuesService.getSchoolID();
      obj.schoolDateTimeSettings.createdBy = this.defaultValuesService.getUserGuidId();
      let apiurl = this.apiUrl + obj._tenantName + "/Common/addUpdateSchoolDateTimeSettings";
      return this.http.post<AddUpdateSchoolDateTimeSettingsModel>(apiurl, obj,this.httpOptions)
    }

    updateSchoolDateTimeSettings(obj: AddUpdateSchoolDateTimeSettingsModel) {
      obj = this.defaultValuesService.getAllMandatoryVariable(obj);
      obj.schoolDateTimeSettings.tenantId = this.defaultValuesService.getTenantID();
      obj.schoolDateTimeSettings.schoolId = this.defaultValuesService.getSchoolID();
      obj.schoolDateTimeSettings.updatedBy = this.defaultValuesService.getUserGuidId();
      let apiurl = this.apiUrl + obj._tenantName + "/Common/addUpdateSchoolDateTimeSettings";
      return this.http.post<AddUpdateSchoolDateTimeSettingsModel>(apiurl, obj,this.httpOptions);
    }

    getAuditHistory(obj: GetAuditHistoryModel) {
      obj = this.defaultValuesService.getAllMandatoryVariable(obj);
      obj.tenantId = this.defaultValuesService.getTenantID();
      obj.schoolId = this.defaultValuesService.getSchoolID();
      let apiurl = this.apiUrl + obj._tenantName + "/Common/getAuditHistory";
      return this.http.post<GetAuditHistoryModel>(apiurl, obj,this.httpOptions);
    }
}

