<div mat-dialog-title class="flex justify-between items-center mb-3">
    <div class="flex justify-between items-start flex-auto">
        <h4 class="font-medium m-0 w-full">{{studentActivityData?.callNature ? studentActivityData?.callNature : (studentActivityData?.notes?.length>16) ? (studentActivityData?.notes | slice:0:40)+'..' : (studentActivityData?.notes)}}</h4>
        <div class="cursor-pointer" [matMenuTriggerFor]="dropdownActivity" *ngIf="(defaultValuesService.getUserMembershipType() !== profiles.Student && defaultValuesService.getUserMembershipType() !== profiles.Parent) && source === 'studentActivity'">
            <mat-icon class="align-middle">more_vert</mat-icon>
        </div>
        <mat-menu #dropdownActivity="matMenu" xPosition="before">
            <button mat-menu-item (click)="editItem()">{{'editItem'|translate}}</button>
            <button mat-menu-item (click)="confirmDeleteActivity(studentActivityData)">{{'deleteItem'|translate}}</button>
        </mat-menu>
    </div>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</div>
<mat-divider class="-mx-6"></mat-divider>
<mat-dialog-content class="pt-6">

    <div class="md:grid grid-cols-2 col-gap-8 row-gap-10">
        <div class="mb-8">
            <label class="text-sm leading-5 text-gray">{{'date' | translate}}</label>
            <p class="mt-1 text-base leading-5 sm:mt-0 sm:col-span-2">{{studentActivityData?.activityDate | date:defaultValuesService.getSchoolDateFormat()}}</p>
        </div>
        <div class="mb-8">
            <label class="text-sm leading-5 text-gray">{{'time' | translate}}</label>
            <p class="mt-1 text-base leading-5 sm:mt-0 sm:col-span-2">{{studentActivityData?.activityTime}}</p>
        </div>
    </div>
    <div class="mb-8" *ngIf="studentActivityData?.callNature">
        <label class="text-sm leading-5 text-gray">{{'natureOfCall' | translate}}</label>
        <p class="mt-1 text-base leading-5 sm:mt-0 sm:col-span-2">{{studentActivityData?.callNature}}</p>
    </div>
    <div class="mb-8">
        <label class="text-sm leading-5 text-gray">{{'notes' | translate}}</label>
        <p class="mt-1 text-base leading-5 sm:mt-0 sm:col-span-2">{{studentActivityData?.notes}}</p>
    </div>
    <div class="mb-8">
        <label class="text-sm leading-5 text-gray">{{'participants' | translate}}</label>
        <p class="mt-1 text-base leading-5 sm:mt-0 sm:col-span-2">{{participantStr}}</p>
    </div>
    <div class="grid grid-cols-2 col-gap-8 row-gap-10">
        <div class="mb-8">
            <label class="text-sm leading-5 text-gray">{{'createdBy' | translate}}</label>
            <p class="mt-1 text-base leading-5 sm:mt-0 sm:col-span-2">{{studentActivityData?.createdBy | EmtyValueCheckPipe}}</p>
        </div>
        <div class="mb-8">
            <label class="text-sm leading-5 text-gray">{{'createdOn' | translate}}</label>
            <p class="mt-1 text-base leading-5 sm:mt-0 sm:col-span-2">{{studentActivityData?.createdOn | date:defaultValuesService.getSchoolDateFormat()}}</p>
        </div>
    </div>
</mat-dialog-content>