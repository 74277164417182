<vex-mat-spinner-overlay *ngIf="isLoading" color="accent"></vex-mat-spinner-overlay>
<div mat-dialog-title class="flex justify-between items-center mb-3">
    <div class="md:flex justify-between items-start flex-auto">
        <h4 class="font-normal text-xl m-0">
            {{ this.data.action == 'add' || this.data.action == 'addOneActivityType' ? ("addActivity" | translate) : ("updateActivity" | translate) }}
        </h4>
    </div>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</div>
<mat-divider class="-mx-6"></mat-divider>
<mat-dialog-content class="mb-1">
    <div class="flex justify-between">
        <div class="custom-tab -mx-6">
            <ul class="flex flex-row">
                <li class="px-6 py-2 cursor-pointer" (click)="changeTab('notes')"
                    [class.active]="currentTab === 'notes'"
                    [ngClass]="currentTab === 'notes' ? 'text-primary border-b-2 border-primary' : ''">
                    <mat-icon [icIcon]="icStickyNote" class="align-middle mr-1"></mat-icon>{{'notes' | translate}}
                </li>
                <li class="px-6 py-2 cursor-pointer" (click)="changeTab('phoneCall')"
                    [class.active]="currentTab === 'phoneCall'"
                    [ngClass]="currentTab === 'phoneCall' ? 'text-primary border-b-2 border-primary' : ''">
                    <mat-icon [icIcon]="icLocalPhone" class="align-middle mr-1"></mat-icon>{{'phoneCall' | translate}}
                </li>
                <li class="px-6 py-2 cursor-pointer" (click)="changeTab('meeting')"
                    [class.active]="currentTab === 'meeting'"
                    [ngClass]="currentTab === 'meeting' ? 'text-primary border-b-2 border-primary' : ''">
                    <mat-icon [icIcon]="icGroups" class="align-middle mr-1"></mat-icon>{{'meeting' | translate}}
                </li>
            </ul>
        </div>
        <div *ngIf="historyViewable" class="flex items-center">
            <mat-icon [icIcon]="icRestore"></mat-icon>
            <mat-slide-toggle color="primary" labelPosition="before" [(ngModel)]="viewHistory" (change)="viewingHistory($event)"><span class="ml-2">{{ 'viewHistory' | translate }}</span></mat-slide-toggle>
        </div>
    </div>
    <mat-divider class="-mx-6"></mat-divider>
    <div *ngIf="!viewHistory" [ngSwitch]="currentTab">
        <div *ngSwitchCase="'notes'" class="pt-6">
          <form [formGroup]="formNotes">
            <ul class="grid grid-cols-2 row-gap-2 col-gap-2">
                <li>
                    <mat-form-field class="w-full" appearance="outline" color="primary">
                        <mat-label>{{'date'|translate}}</mat-label>
                        <input formControlName="date" matInput [matDatepicker]="date" required>
                        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                        <mat-error *ngIf="formNotes.controls.date?.errors?.required">{{'dateRequired' | translate}}</mat-error>
                    </mat-form-field>
                </li>
                <li>
                    <mat-form-field class="w-full" appearance="outline" color="primary">
                        <mat-label>{{'time'|translate}}</mat-label>
                        <input formControlName="time" onkeydown="return false" name="time" [owlDateTimeTrigger]="time" [owlDateTime]="time" matInput required>
                        <owl-date-time [pickerType]="'timer'" [hour12Timer]='true' #time></owl-date-time>
                        <mat-error *ngIf="formNotes.controls.time?.errors?.required">{{'timeRequired' | translate}}</mat-error>
                    </mat-form-field>
                </li>
            </ul>
            <div>
                <mat-form-field class="w-full" appearance="outline" color="primary">
                    <mat-label>{{'notes'|translate}}</mat-label>
                    <textarea formControlName="notes" matInput class="min-h-full h-40" required></textarea>
                    <mat-error *ngIf="formNotes.controls.notes?.errors?.required">{{'notesRequired' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="border border-normal rounded px-3 py-1 relative">
              <div>
                <label class="text-gray w-full">{{ 'participants' | translate }}<span class="ml-1">*</span></label>
                <mat-chip-list #chipList aria-label="participant selection">
                    <mat-chip
                      *ngFor="let toRecipient of composeMessageDefaultData[0].recipientInfoViewModels"
                      (removed)="removeRecipientData(toRecipient, 'isTo')">
                      {{toRecipient.title}}
                      <button matChipRemove>
                        <mat-icon class="text-lg">cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="{{ 'searchRecipients' | translate }}"
                      #toChipList
                      [formControl]="toSearchCtrl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="sendUserValue($event)">
                    <mat-option *ngFor="let user of userList" [value]="user">
                        {{user.firstGivenName}}{{user.middleName ? ' '+user.middleName+' ': ' '}}{{user.lastFamilyName}}
                    </mat-option>
                  </mat-autocomplete>
                <!-- <div class="flex justify-between items-center" *ngIf="participantList">
                    <p class="pr-4">{{getAllSelectedTitle()}}</p>
                    <div class="cursor-pointer" (click)="addParticipants()">
                        <mat-icon [icIcon]="icEdit" class="text-gray"></mat-icon>
                    </div>
                </div> -->
                <!-- <div class="search-wrapper flex flex-wrap mt-2" *ngIf="showParticipant"> -->
                    <!-- <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Danny Anderson</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Joe Root</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Mohit Sharma</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Laura Palva</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Colin Parker</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <input type="text" placeholder="{{ 'searchRecipients' | translate }}" class="mr-2 mb-2 text-sm" (click)="onSearchRecipient()"> -->
                    <!-- <mat-form-field class="example-chip-list" appearance="fill">
                        <mat-chip-list #toChipList>
                            <mat-chip *ngFor="let toRecipient of composeMessageDefaultData[0].recipientInfoViewModels" [selectable]="selectable" [removable]="removable"
                                (removed)="removeRecipientData(toRecipient, 'isTo')">
                                {{toRecipient.title}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input type="text" [formControl]="toSearchCtrl" placeholder="{{ 'searchRecipients' | translate }}" [matChipInputFor]="toChipList"
                             class="mr-2 mb-2 text-sm" (click)="onSearchRecipient()" />
                        </mat-chip-list>
                    </mat-form-field>
                </div> -->
                <!-- <mat-error *ngIf="participantControl.errors?.required">{{'participantsRequired' | translate}}</mat-error> -->
              <!-- </div> -->

                <!-- <div class="flex absolute bg-white z-10 w-full border border-normal shadow-md left-0 rounded mt-4" *ngIf="serchDetails"> -->
                    <!-- <div class="w-1/3 p-4">
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Students in this School', 1)">
                            <mat-icon [icIcon]="icSchool" class="align-middle mr-2"></mat-icon><span>{{ 'allStudentsInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Staff in this school', 2)">
                            <mat-icon [icIcon]="icGroup" class="align-middle mr-2"></mat-icon><span>{{ 'allStaffInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Teachers in this School', 3)">
                            <mat-icon [icIcon]="icPersonOutline" class="align-middle mr-2"></mat-icon><span>{{ 'allTeachersInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Parents in this school', 4)">
                            <mat-icon [icIcon]="icEscalatorWarning" class="align-middle mr-2"></mat-icon><span>{{ 'allParentsInThisSchool' | translate }}</span>
                        </div>
                    </div>
                    <div class="w-2/3 border-l border-normal"> -->
                        <!-- <div class="p-4" *ngIf="!isAllUserSearched">
                            <div>
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="isVisible">
                                    <mat-radio-button [value]="'byGradeLevel'"  name="isVisible" class="mr-6 mb-2" color="primary">{{ 'searchByGradeLevel' | translate }}</mat-radio-button>
                                    <mat-radio-button [value]="'byCourseSection'"  name="isVisible" class="mr-6 mb-2" color="primary">{{ 'searchByCourseSection' | translate }}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="isVisible === 'byGradeLevel'">
                                <div class="flex my-4">
                                    <div class="mr-2">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'gradeLevel' | translate }}</mat-label>
                                            <mat-select [(ngModel)]="gradeLevelData.id">
                                            <mat-option *ngFor="let gradeLevel of gradeLevelList" [value]='gradeLevel.gradeId' (click)="selectGradeLevel(gradeLevel)">{{gradeLevel.title}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex flex-col ml-2">
                                        <mat-checkbox class="mb-1" color="primary" [checked]="true" [(ngModel)]="gradeLevelData.allStudents">{{ 'allStudents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-1" color="primary" [checked]="true" [(ngModel)]="gradeLevelData.allParents">{{ 'allParents' | translate }}</mat-checkbox>
                                    </div>
                                </div>
                            </div>
        
                            <div *ngIf="isVisible === 'byCourseSection'">
                                <div class="my-4">
                                    <div class="flex">
                                        <div class="mr-2">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'course' | translate }}</mat-label>
                                                <mat-select [(ngModel)]="selectedCourse" (selectionChange)="selectedCourseSection = 0; selectedCourseAndCourseSection()">
                                                <mat-option *ngFor="let course of courseList; let i = index" [value]="i">{{course.course.courseTitle}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="mr-2">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'courseSection' | translate }}</mat-label>
                                                <mat-select [(ngModel)]="selectedCourseSection" (selectionChange)="selectedCourseAndCourseSection()">
                                                    <ng-container *ngIf="selectedCourse !== '' && selectedCourse !== undefined && selectedCourse !== null">
                                                        <mat-option *ngFor="let courseSection of courseList[selectedCourse]?.course?.courseSection; let i = index;" [value]="i">{{courseSection.courseSectionName}}</mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <mat-checkbox class="mb-2 mr-6" color="primary" [checked]="true" [(ngModel)]="courseSectionData.allStudents">{{ 'allStudents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-2 mr-6" color="primary" [(ngModel)]="courseSectionData.allParents">{{ 'allParents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-2 mr-6" color="primary" [(ngModel)]="courseSectionData.allTeacher">{{ 'allTeacher' | translate }}</mat-checkbox>
                                    </div>
                                </div>
                            </div>
        
                            <div class="flex justify-end">
                                <button mat-flat-button (click)="onSearchRecipient()">{{ 'cancel' | translate }}</button>
                                <button color="primary" mat-flat-button type="submit" class="text-white ml-2" (click)="addData()">{{ 'add' |translate }}</button>
                            </div>
                        </div> -->
        
                        <!-- Start Search By Name -->
                        <!-- <div class="h-48 overflow-y-auto p-4" *ngIf="isAllUserSearched">
                            <div class="flex flex-col">
                                <div class="flex justify-between items-center my-1 cursor-pointer" [class.disabled]="user.isDisabled" [class.text-gray-light]="user.isDisabled" *ngFor="let user of userList" (click)="sendUserValue(user)">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img *ngIf="user.thumbnailPhoto" [src]="'data:image/jpeg;base64,'+ user.thumbnailPhoto" alt="Profile Photo" class="rounded-full">
                                            <p *ngIf="!user.thumbnailPhoto" class="text-white text-center p-2 leading-tight" [ngClass]="user.bgColor">{{user.firstNameChar}}</p>
                                        </div>
                                        <p>{{user.firstGivenName}}{{user.middleName ? ' '+user.middleName+' ': ' '}}{{user.lastFamilyName}}</p>
                                    </div>
                                    <div>{{user.userProfile}}</div>
                                </div> -->
        
                                <!-- <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <p class="bg-green text-white text-center p-2 leading-tight">S</p>
                                        </div>
                                        <p>Santiago Martinez</p>
                                    </div>
                                    <div>Student</div>
                                </div>
        
                                <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hiddenrounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Sage Harper</p>
                                    </div>
                                    <div>Student</div>
                                </div>
        
                                <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Sarah Livingston</p>
                                    </div>
                                    <div>Teacher</div>
                                </div>
        
                                <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Salavdor Blevins</p>
                                    </div>
                                    <div>Parent</div>
                                </div> -->
                            <!-- </div>
                        </div> -->
                        <!-- End Search By Name -->
                    <!-- </div> -->
                </div>
            </div>
           </form>
        </div>

        <div *ngSwitchCase="'phoneCall'" class="pt-6">
          <form [formGroup]="formPhone">
            <ul class="grid grid-cols-2 row-gap-2 col-gap-2">
                <li>
                    <mat-form-field class="w-full" appearance="outline" color="primary">
                        <mat-label>{{'date'|translate}}</mat-label>
                        <input formControlName="date" matInput [matDatepicker]="date" required>
                        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                        <mat-error *ngIf="formPhone.controls.date?.errors?.required">{{'dateRequired' | translate}}</mat-error>
                    </mat-form-field>
                </li>
                <li>
                    <mat-form-field class="w-full" appearance="outline" color="primary">
                        <mat-label>{{'time'|translate}}</mat-label>
                        <input formControlName="time" onkeydown="return false" name="time" [owlDateTimeTrigger]="time" [owlDateTime]="time" matInput required>
                        <owl-date-time [pickerType]="'timer'" [hour12Timer]='true' #time></owl-date-time>
                        <mat-error *ngIf="formPhone.controls.time?.errors?.required">{{'timeRequired' | translate}}</mat-error>
                    </mat-form-field>
                </li>
            </ul>
            <div>
                <mat-form-field class="w-full" appearance="outline" color="primary">
                    <mat-label>{{'natureOfCall'|translate}}</mat-label>
                    <input formControlName="natureOfCall" matInput required>
                    <mat-error *ngIf="formPhone.controls.natureOfCall?.errors?.required">{{'natureOfCallRequired' | translate}}</mat-error>
                    <mat-error *ngIf="formPhone.controls.natureOfCall?.errors?.maxlength">{{defaultValuesService.maxLengthValidationErrMsg(formPhone.controls.natureOfCall?.errors?.maxlength.requiredLength, formPhone.controls.natureOfCall?.errors?.maxlength.actualLength)}}</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-full" appearance="outline" color="primary">
                    <mat-label>{{'notes'|translate}}</mat-label>
                    <textarea formControlName="notes" matInput class="min-h-full h-40" required></textarea>
                    <mat-error *ngIf="formPhone.controls.notes?.errors?.required">{{'notesRequired' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="border border-normal rounded px-3 py-1 relative">
              <div>
                <label class="text-gray w-full">{{ 'participants' | translate }}<span class="ml-1">*</span></label>
                <mat-chip-list #chipList aria-label="participant selection">
                    <mat-chip
                      *ngFor="let toRecipient of composeMessageDefaultData[0].recipientInfoViewModels"
                      (removed)="removeRecipientData(toRecipient, 'isTo')">
                      {{toRecipient.title}}
                      <button matChipRemove>
                        <mat-icon class="text-lg">cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="{{ 'searchRecipients' | translate }}"
                      #toChipList
                      [formControl]="toSearchCtrl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="sendUserValue($event)">
                    <mat-option *ngFor="let user of userList" [value]="user">
                        {{user.firstGivenName}}{{user.middleName ? ' '+user.middleName+' ': ' '}}{{user.lastFamilyName}}
                    </mat-option>
                  </mat-autocomplete>

                <!-- <div class="flex justify-between items-center" *ngIf="participantList">
                    <p class="pr-4">{{getAllSelectedTitle()}}</p>
                    <div class="cursor-pointer" (click)="addParticipants()">
                        <mat-icon [icIcon]="icEdit" class="text-gray"></mat-icon>
                    </div>
                </div>
                <div class="search-wrapper flex flex-wrap mt-2" *ngIf="showParticipant"> -->
                    <!-- <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Danny Anderson</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Joe Root</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Mohit Sharma</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Laura Palva</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Colin Parker</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <input type="text" placeholder="{{ 'searchRecipients' | translate }}" class="mr-2 mb-2 text-sm" (click)="onSearchRecipient()"> -->
                    <!-- <mat-form-field class="example-chip-list" appearance="fill">
                        <mat-chip-list #toChipList>
                            <mat-chip *ngFor="let toRecipient of composeMessageDefaultData[0].recipientInfoViewModels" [selectable]="selectable" [removable]="removable"
                                (removed)="removeRecipientData(toRecipient, 'isTo')">
                                {{toRecipient.title}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input type="text" [formControl]="toSearchCtrl" placeholder="{{ 'searchRecipients' | translate }}" [matChipInputFor]="toChipList"
                             class="mr-2 mb-2 text-sm" (click)="onSearchRecipient()"/>
                        </mat-chip-list>
                    </mat-form-field> -->
                <!-- </div> -->
              <!-- </div> -->

                <!-- <div class="flex absolute bg-white z-10 w-full border border-normal shadow-md left-0 rounded mt-4" *ngIf="serchDetails">
                    <div class="w-1/3 p-4">
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Students in this School', 1)">
                            <mat-icon [icIcon]="icSchool" class="align-middle mr-2"></mat-icon><span>{{ 'allStudentsInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Staff in this school', 2)">
                            <mat-icon [icIcon]="icGroup" class="align-middle mr-2"></mat-icon><span>{{ 'allStaffInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Teachers in this School', 3)">
                            <mat-icon [icIcon]="icPersonOutline" class="align-middle mr-2"></mat-icon><span>{{ 'allTeachersInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Parents in this school', 4)">
                            <mat-icon [icIcon]="icEscalatorWarning" class="align-middle mr-2"></mat-icon><span>{{ 'allParentsInThisSchool' | translate }}</span>
                        </div>
                    </div>
                    <div class="w-2/3 border-l border-normal">
                        <div class="p-4">
                            <div>
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="isVisible">
                                    <mat-radio-button [value]="'byGradeLevel'"  name="isVisible" class="mr-6 mb-2" color="primary">{{ 'searchByGradeLevel' | translate }}</mat-radio-button>
                                    <mat-radio-button [value]="'byCourseSection'"  name="isVisible" class="mr-6 mb-2" color="primary">{{ 'searchByCourseSection' | translate }}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="isVisible === 'byGradeLevel'">
                                <div class="flex my-4">
                                    <div class="mr-2">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'gradeLevel' | translate }}</mat-label>
                                            <mat-select>
                                            <mat-option>Grade 9</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex flex-col ml-2">
                                        <mat-checkbox class="mb-1" color="primary" [checked]="true">{{ 'allStudents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-1" color="primary" [checked]="true">{{ 'allParents' | translate }}</mat-checkbox>
                                    </div>
                                </div>
                            </div>
        
                            <div *ngIf="isVisible === 'byCourseSection'">
                                <div class="my-4">
                                    <div class="flex">
                                        <div class="mr-2">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'course' | translate }}</mat-label>
                                                <mat-select>
                                                <mat-option>Chemistry</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="mr-2">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'courseSection' | translate }}</mat-label>
                                                <mat-select>
                                                <mat-option>CHEM001</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <mat-checkbox class="mb-2 mr-6" color="primary" [checked]="true">{{ 'allStudents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-2 mr-6" color="primary">{{ 'allParents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-2 mr-6" color="primary">{{ 'allTeacher' | translate }}</mat-checkbox>
                                    </div>
                                </div>
                            </div>
        
                            <div class="flex justify-end">
                                <button mat-flat-button (click)="onSearchRecipient()">{{ 'cancel' | translate }}</button>
                                <button color="primary" mat-flat-button type="submit" class="text-white ml-2" >{{ 'add' |translate }}</button>
                            </div>
                        </div> -->
        
                        <!-- Start Search By Name -->
                        <!-- <div class="h-48 overflow-y-auto p-4" *ngIf="isAllUserSearched">
                            <div class="flex flex-col">
                                <div class="flex justify-between items-center my-1 cursor-pointer" [class.disabled]="user.isDisabled" [class.text-gray-light]="user.isDisabled" *ngFor="let user of userList" (click)="sendUserValue(user)">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img *ngIf="user.thumbnailPhoto" [src]="'data:image/jpeg;base64,'+ user.thumbnailPhoto" alt="Profile Photo" class="rounded-full">
                                            <p *ngIf="!user.thumbnailPhoto" class="text-white text-center p-2 leading-tight" [ngClass]="user.bgColor">{{user.firstNameChar}}</p>
                                        </div>
                                        <p>{{user.firstGivenName}}{{user.middleName ? ' '+user.middleName+' ': ' '}}{{user.lastFamilyName}}</p>
                                    </div>
                                    <div>{{user.userProfile}}</div>
                                </div> -->
        
                                <!-- <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <p class="bg-green text-white text-center p-2 leading-tight">S</p>
                                        </div>
                                        <p>Santiago Martinez</p>
                                    </div>
                                    <div>Student</div>
                                </div>
        
                                <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hiddenrounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Sage Harper</p>
                                    </div>
                                    <div>Student</div>
                                </div>
        
                                <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Sarah Livingston</p>
                                    </div>
                                    <div>Teacher</div>
                                </div>
        
                                <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Salavdor Blevins</p>
                                    </div>
                                    <div>Parent</div>
                                </div>
                            </div>
                        </div> -->
                        <!-- End Search By Name -->
                    <!-- </div> -->
                </div>
            </div>

            <div class="flex border border-normal rounded bg-skyblue-light p-4 mt-4 items-center">
                <div class="flex-shrink-0 mr-4">
                    <img src="assets/img/alarm.svg" alt="Reminder Clock">
                </div>
                <div>
                    <h4 class="text-primary font-semibold text-lg">{{'reminder' | translate }}</h4>
                    <p class="mb-2">{{'youCanSetReminderAboutYourFutureActivitiesThisReminderWillShowOnYourDashboard' | translate }}</p>
                    <div>
                        <mat-checkbox color="primary" class="mr-6 mb-2" formControlName="onThatDayCheck" (change)="reminderCheck($event, 'onThatDay')">{{'onThatDay' | translate }}</mat-checkbox>
                        <mat-checkbox color="primary" class="mr-6 mb-2" formControlName="onPreviousDayCheck" (change)="reminderCheck($event, 'onPreviousDay')">{{'onPreviousDay' | translate }}</mat-checkbox>
                    </div>
                </div>
            </div>
          </form>
        </div>

        <div *ngSwitchCase="'meeting'" class="pt-6">
          <form [formGroup]="formMeeting">
            <ul class="grid grid-cols-2 row-gap-2 col-gap-2">
                <li>
                    <mat-form-field class="w-full" appearance="outline" color="primary">
                        <mat-label>{{'date'|translate}}</mat-label>
                        <input formControlName="date" matInput [matDatepicker]="date" required>
                        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                        <mat-error *ngIf="formMeeting.controls.date?.errors?.required">{{'dateRequired' | translate}}</mat-error>
                    </mat-form-field>
                </li>
                <li>
                    <mat-form-field class="w-full" appearance="outline" color="primary">
                        <mat-label>{{'time'|translate}}</mat-label>
                        <input formControlName="time" onkeydown="return false" name="time" [owlDateTimeTrigger]="time" [owlDateTime]="time" matInput required>
                        <owl-date-time [pickerType]="'timer'" [hour12Timer]='true' #time></owl-date-time>
                        <mat-error *ngIf="formMeeting.controls.time?.errors?.required">{{'timeRequired' | translate}}</mat-error>
                    </mat-form-field>
                </li>
            </ul>
            <div>
                <mat-form-field class="w-full" appearance="outline" color="primary">
                    <mat-label>{{'purpose'|translate}}</mat-label>
                    <input formControlName="purpose" matInput required>
                    <mat-error *ngIf="formMeeting.controls.purpose?.errors?.required">{{'purposeRequired' | translate}}</mat-error>
                    <mat-error *ngIf="formMeeting.controls.purpose?.errors?.maxlength">{{defaultValuesService.maxLengthValidationErrMsg(formMeeting.controls.purpose?.errors?.maxlength.requiredLength, formMeeting.controls.purpose?.errors?.maxlength.actualLength)}}</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-full" appearance="outline" color="primary">
                    <mat-label>{{'meetingAgenda'|translate}}</mat-label>
                    <textarea formControlName="meetingAgenda" matInput class="min-h-full h-40" required></textarea>
                    <mat-error *ngIf="formMeeting.controls.meetingAgenda?.errors?.required">{{'meetingAgendaRequired' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="border border-normal rounded px-3 py-1 relative">
              <div>
                <label class="text-gray w-full">{{ 'participants' | translate }}<span class="ml-1">*</span></label>
                <mat-chip-list #chipList aria-label="participant selection">
                    <mat-chip
                      *ngFor="let toRecipient of composeMessageDefaultData[0].recipientInfoViewModels"
                      (removed)="removeRecipientData(toRecipient, 'isTo')">
                      {{toRecipient.title}}
                      <button matChipRemove>
                        <mat-icon class="text-lg">cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="{{ 'searchRecipients' | translate }}"
                      #toChipList
                      [formControl]="toSearchCtrl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="sendUserValue($event)">
                    <mat-option *ngFor="let user of userList" [value]="user">
                        {{user.firstGivenName}}{{user.middleName ? ' '+user.middleName+' ': ' '}}{{user.lastFamilyName}}
                    </mat-option>
                  </mat-autocomplete>

                <!-- <div class="flex justify-between items-center" *ngIf="participantList">
                    <p class="pr-4">{{getAllSelectedTitle()}}</p>
                    <div class="cursor-pointer" (click)="addParticipants()">
                        <mat-icon [icIcon]="icEdit" class="text-gray"></mat-icon>
                    </div>
                </div>
                <div class="search-wrapper flex flex-wrap mt-2" *ngIf="showParticipant"> -->
                    <!-- <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Danny Anderson</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Joe Root</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Mohit Sharma</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Laura Palva</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                        <p>Colin Parker</p>
                        <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                    </div>
                    <input type="text" placeholder="{{ 'searchRecipients' | translate }}" class="mr-2 mb-2 text-sm" (click)="onSearchRecipient()"> -->
                    <!-- <mat-form-field class="example-chip-list" appearance="fill">
                        <mat-chip-list #toChipList>
                            <mat-chip *ngFor="let toRecipient of composeMessageDefaultData[0].recipientInfoViewModels" [selectable]="selectable" [removable]="removable"
                                (removed)="removeRecipientData(toRecipient, 'isTo')">
                                {{toRecipient.title}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input type="text" [formControl]="toSearchCtrl" placeholder="{{ 'searchRecipients' | translate }}" [matChipInputFor]="toChipList"
                             class="mr-2 mb-2 text-sm" (click)="onSearchRecipient()" />
                        </mat-chip-list>
                    </mat-form-field>
                </div>
              </div> -->

                <!-- <div class="flex absolute bg-white z-10 w-full border border-normal shadow-md left-0 rounded mt-4" *ngIf="serchDetails">
                    <div class="w-1/3 p-4">
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Students in this School', 1)">
                            <mat-icon [icIcon]="icSchool" class="align-middle mr-2"></mat-icon><span>{{ 'allStudentsInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Staff in this school', 2)">
                            <mat-icon [icIcon]="icGroup" class="align-middle mr-2"></mat-icon><span>{{ 'allStaffInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Teachers in this School', 3)">
                            <mat-icon [icIcon]="icPersonOutline" class="align-middle mr-2"></mat-icon><span>{{ 'allTeachersInThisSchool' | translate }}</span>
                        </div>
                        <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Parents in this school', 4)">
                            <mat-icon [icIcon]="icEscalatorWarning" class="align-middle mr-2"></mat-icon><span>{{ 'allParentsInThisSchool' | translate }}</span>
                        </div>
                    </div>
                    <div class="w-2/3 border-l border-normal">
                        <div class="p-4">
                            <div>
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="isVisible">
                                    <mat-radio-button [value]="'byGradeLevel'"  name="isVisible" class="mr-6 mb-2" color="primary">{{ 'searchByGradeLevel' | translate }}</mat-radio-button>
                                    <mat-radio-button [value]="'byCourseSection'"  name="isVisible" class="mr-6 mb-2" color="primary">{{ 'searchByCourseSection' | translate }}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="isVisible === 'byGradeLevel'">
                                <div class="flex my-4">
                                    <div class="mr-2">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'gradeLevel' | translate }}</mat-label>
                                            <mat-select>
                                            <mat-option>Grade 9</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex flex-col ml-2">
                                        <mat-checkbox class="mb-1" color="primary" [checked]="true">{{ 'allStudents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-1" color="primary" [checked]="true">{{ 'allParents' | translate }}</mat-checkbox>
                                    </div>
                                </div>
                            </div>
        
                            <div *ngIf="isVisible === 'byCourseSection'">
                                <div class="my-4">
                                    <div class="flex">
                                        <div class="mr-2">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'course' | translate }}</mat-label>
                                                <mat-select>
                                                <mat-option>Chemistry</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="mr-2">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'courseSection' | translate }}</mat-label>
                                                <mat-select>
                                                <mat-option>CHEM001</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <mat-checkbox class="mb-2 mr-6" color="primary" [checked]="true">{{ 'allStudents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-2 mr-6" color="primary">{{ 'allParents' | translate }}</mat-checkbox>
                                        <mat-checkbox class="mb-2 mr-6" color="primary">{{ 'allTeacher' | translate }}</mat-checkbox>
                                    </div>
                                </div>
                            </div>
        
                            <div class="flex justify-end">
                                <button mat-flat-button (click)="onSearchRecipient()">{{ 'cancel' | translate }}</button>
                                <button color="primary" mat-flat-button type="submit" class="text-white ml-2" >{{ 'add' |translate }}</button>
                            </div>
                        </div> -->
        
                        <!-- Start Search By Name -->
                        <!-- <div class="h-48 overflow-y-auto p-4" *ngIf="isAllUserSearched">
                            <div class="flex flex-col">
                                <div class="flex justify-between items-center my-1 cursor-pointer" [class.disabled]="user.isDisabled" [class.text-gray-light]="user.isDisabled" *ngFor="let user of userList" (click)="sendUserValue(user)">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img *ngIf="user.thumbnailPhoto" [src]="'data:image/jpeg;base64,' + user.thumbnailPhoto" alt="Profile Photo" class="rounded-full">
                                            <p *ngIf="!user.thumbnailPhoto" class="text-white text-center p-2 leading-tight" [ngClass]="user.bgColor">{{user.firstNameChar}}</p>
                                        </div>
                                        <p>{{user.firstGivenName}}{{user.middleName ? ' '+user.middleName+' ': ' '}}{{user.lastFamilyName}}</p>
                                    </div>
                                    <div>{{user.userProfile}}</div>
                                </div> -->
        
                            <!-- <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <p class="bg-green text-white text-center p-2 leading-tight">S</p>
                                        </div>
                                        <p>Santiago Martinez</p>
                                    </div>
                                    <div>Student</div>
                                </div> -->
        
                                <!-- <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hiddenrounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Sage Harper</p>
                                    </div>
                                    <div>Student</div>
                                </div> -->
        
                                <!-- <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Sarah Livingston</p>
                                    </div>
                                    <div>Teacher</div>
                                </div> -->
        
                                <!-- <div class="flex justify-between items-center my-1 cursor-pointer">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                                            <img src="assets/img/default.jpg" alt="Profile Photo" class="rounded-full">
                                        </div>
                                        <p>Salavdor Blevins</p>
                                    </div>
                                    <div>Parent</div>
                                </div> -->
                            <!-- </div>
                        </div> -->
                        <!-- End Search By Name -->
                    <!-- </div> -->
                </div>
            </div>

            <div class="flex border border-normal rounded bg-skyblue-light p-4 mt-4 items-center">
                <div class="flex-shrink-0 mr-4">
                    <img src="assets/img/alarm.svg" alt="Reminder Clock">
                </div>
                <div>
                    <h4 class="text-primary font-semibold text-lg">{{'reminder' | translate }}</h4>
                    <p class="mb-2">{{'youCanSetReminderAboutYourFutureActivitiesThisReminderWillShowOnYourDashboard' | translate }}</p>
                    <div>
                        <mat-checkbox color="primary" class="mr-6 mb-2" formControlName="onThatDayCheck" (change)="reminderCheck($event, 'onThatDay')">{{'onThatDay' | translate }}</mat-checkbox>
                        <mat-checkbox color="primary" class="mr-6 mb-2" formControlName="onPreviousDayCheck" (change)="reminderCheck($event, 'onPreviousDay')">{{'onPreviousDay' | translate }}</mat-checkbox>
                    </div>
                </div>
            </div>
          </form>
        </div>
    </div>

    <div *ngIf="viewHistory" class="-mx-6">
        <div class="flex items-center px-4 py-2 alert-data-wrapper rounded my-4 mx-6 mb-0">
            <mat-icon [icIcon]="icInfo" class="mr-2"></mat-icon>
            <p class="text-sm">{{'youreViewingTheActivityHistoryOfTheStudent' | translate}}, <span class="font-semibold">{{studentFullName}}</span></p>
        </div>
        <div class="overflow-x-auto overflow-y-hidden">
            <table mat-table [dataSource]="studentActivityData" class="mat-elevation-z8" matSort>
                <ng-container matColumnDef="activities">
                    <th mat-header-cell *matHeaderCellDef>{{'activities' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="w-4/6">
                        <div class="flex items-center truncate">
                            <mat-icon [icIcon]="element.activityType == 'Notes' ? icStickyNote: element.activityType == 'Phone' ? icLocalPhone : icGroups" class="mr-4"></mat-icon>
                            <p class="truncate white-space-normal py-2">{{element.notes}}</p>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef class="text-right">{{'date' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="text-right"> {{element.activityDate | date:defaultValuesService.getSchoolDateFormat()}}{{', '}}{{element.activityTime}} </td>
                </ng-container>
        
                <ng-container matColumnDef="noData">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="colspanLength">
                        {{ isLoading ? ('fetchingData' | translate) : ('noData' | translate) }}
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="visibleCols"></tr>
                <tr mat-row *matRowDef="let row; columns: visibleCols"></tr>
                <tr mat-footer-row *matFooterRowDef="['noData']" [class.hidden]="studentActivityData.data.length != 0 && studentActivityData.data.length != null"></tr>
            </table>
        </div>
        <mat-paginator [length]="studentActivityData.data.length" [class.hidden]="studentActivityData.data.length <= 0" [pageSize]="pageSize" [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!viewHistory" class="py-4 flex justify-end">
    <button mat-dialog-close mat-flat-button>{{ 'cancel' | translate }}</button>
    <button color="primary" mat-flat-button type="submit" class="text-white ml-2" (click)="onSubmit()">{{this.data.action == 'add' || this.data.action == 'addOneActivityType'? ('submit' |translate) : ('update' | translate)}}</button>
</mat-dialog-actions>