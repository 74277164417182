export enum currencyEnum {
  USD = 'United States Dollar',
  EUR = 'Euro',
  GBP = 'British Pound',
  AUD = 'Australian Dollar',
  BRL = 'Brazilian Real',
  CAD = 'Canadian Dollar',
  CNY = 'Chinese Yuan',
  EGP = 'Egyptian Pound',
  HKD = 'Hong Kong Dollar',
  INR = 'Indian Rupee',
  IDR = 'Indonesian Rupiah',
  JPY = 'Japanese Yen',
  MYR = 'Malaysian Ringgit',
  MXN = 'Mexican Peso',
  NZD = 'New Zealand Dollar',
  NOK = 'Norwegian Krone',
  PHP = 'Philippine Peso',
  SGD = 'Singapore Dollar',
  SEK = 'Swedish Krona',
  CHF = 'Swiss Franc',
  THB = 'Thai Baht',
  AED = 'UAE Dirham',
  VND = 'Vietnamese Dong',
  NGN = 'Nigerian Naira',
  KES = 'Kenyan Shilling'
}

export enum currencySymbol {
  USD = '$',
  AUD = 'A$',
  BRL = 'R$',
  GBP = '£',
  CAD = 'CA$',
  CNY = 'CN¥',
  EGP = 'EGP',
  EUR = '€',
  HKD = 'HK$',
  INR = '₹',
  IDR = 'Rp',
  JPY = '¥',
  MYR = 'MYR',
  MXN = 'MX$',
  NZD = 'NZ$',
  NOK = 'NOK',
  PHP = '₱',
  SGD = 'SGD',
  SEK = 'SEK',
  CHF = 'CHF',
  THB = 'THB',
  AED = 'AED',
  VND = '₫',
  NGN = 'NGN',
  KES = 'KES'
}